var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { ConfigContext } from '../config-provider';
import { cloneElement } from '../_util/reactNode';
function getNumberArray(num) {
    return num
        ? num
            .toString()
            .split('')
            .reverse()
            .map(i => {
            const current = Number(i);
            return isNaN(current) ? i : current;
        })
        : [];
}
function renderNumberList(position, className) {
    const childrenToReturn = [];
    for (let i = 0; i < 30; i++) {
        childrenToReturn.push(<p key={i.toString()} className={classNames(className, {
            current: position === i,
        })}>
        {i % 10}
      </p>);
    }
    return childrenToReturn;
}
const ScrollNumber = (_a) => {
    var { prefixCls: customizePrefixCls, count: customizeCount, className, style, title, show, component = 'sup', children, onAnimated = () => { } } = _a, restProps = __rest(_a, ["prefixCls", "count", "className", "style", "title", "show", "component", "children", "onAnimated"]);
    const [animateStarted, setAnimateStarted] = React.useState(true);
    const [count, setCount] = React.useState(customizeCount);
    const [prevCount, setPrevCount] = React.useState(customizeCount);
    const [lastCount, setLastCount] = React.useState(customizeCount);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('scroll-number', customizePrefixCls);
    if (prevCount !== customizeCount) {
        setAnimateStarted(true);
        setPrevCount(customizeCount);
    }
    React.useEffect(() => {
        setLastCount(count);
        let timeout;
        if (animateStarted) {
            // Let browser has time to reset the scroller before actually
            // performing the transition.
            timeout = setTimeout(() => {
                setAnimateStarted(false);
                setCount(customizeCount);
                onAnimated();
            });
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [animateStarted, customizeCount, onAnimated]);
    // =========================== Function ===========================
    const getPositionByNum = (num, i) => {
        const currentCount = Math.abs(Number(count));
        const lstCount = Math.abs(Number(lastCount));
        const currentDigit = Math.abs(getNumberArray(count)[i]);
        const lastDigit = Math.abs(getNumberArray(lstCount)[i]);
        if (animateStarted) {
            return 10 + num;
        }
        // 同方向则在同一侧切换数字
        if (currentCount > lstCount) {
            if (currentDigit >= lastDigit) {
                return 10 + num;
            }
            return 20 + num;
        }
        if (currentDigit <= lastDigit) {
            return 10 + num;
        }
        return num;
    };
    // ============================ Render ============================
    const newProps = Object.assign(Object.assign({}, restProps), { 'data-show': show, style, className: classNames(prefixCls, className), title: title });
    const renderCurrentNumber = (num, i) => {
        if (typeof num === 'number') {
            const position = getPositionByNum(num, i);
            const removeTransition = animateStarted || getNumberArray(lastCount)[i] === undefined;
            return React.createElement('span', {
                className: `${prefixCls}-only`,
                style: {
                    transition: removeTransition ? 'none' : undefined,
                    msTransform: `translateY(${-position * 100}%)`,
                    WebkitTransform: `translateY(${-position * 100}%)`,
                    transform: `translateY(${-position * 100}%)`,
                },
                key: i,
            }, renderNumberList(position, `${prefixCls}-only-unit`));
        }
        return (<span key="symbol" className={`${prefixCls}-symbol`}>
        {num}
      </span>);
    };
    const numberNodeRef = React.useRef(null);
    if (show) {
        numberNodeRef.current =
            count && Number(count) % 1 === 0
                ? getNumberArray(count)
                    .map((num, i) => renderCurrentNumber(num, i))
                    .reverse()
                : count;
    }
    // allow specify the border
    // mock border-color by box-shadow for compatible with old usage:
    // <Badge count={4} style={{ backgroundColor: '#fff', color: '#999', borderColor: '#d9d9d9' }} />
    if (style && style.borderColor) {
        newProps.style = Object.assign(Object.assign({}, style), { boxShadow: `0 0 0 1px ${style.borderColor} inset` });
    }
    if (children) {
        return cloneElement(children, oriProps => ({
            className: classNames(`${prefixCls}-custom-component`, oriProps === null || oriProps === void 0 ? void 0 : oriProps.className),
        }));
    }
    return React.createElement(component, newProps, numberNodeRef.current);
};
export default ScrollNumber;
