var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import RcImage, { ImageProps } from 'rc-image';
import { ConfigContext } from '../config-provider';
const Image = (_a) => {
    var { prefixCls: customizePrefixCls } = _a, otherProps = __rest(_a, ["prefixCls"]);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('image', customizePrefixCls);
    return <RcImage prefixCls={prefixCls} {...otherProps}/>;
};
export { ImageProps };
export default Image;
